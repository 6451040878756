import {PanelsCurationEventsSort} from '__generated__/globalTypes';
import _isEmpty from 'lodash/isEmpty';
import {combineReducers} from 'redux';
import {createAction, createReducer} from 'redux-act';
import {call, put, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global';
import {searchActivities} from 'services/graphql/PanelsActivityService';
import {debounce} from 'utils/sagas';
import {isSortDescending} from 'utils/string';

import type {Query} from 'bkn-ui';
import type {Action} from 'redux-act';
import type {ActivitiesPageQuery, Activity} from 'models';

// ACTIONS

export const actions = {
  fetchInitialData: createAction<ActivitiesPageQuery>('activities/INITIAL_FETCH_REQUESTED'),
  fetchActivities: createAction<ActivitiesPageQuery>('activities/ACTIVITIES_FETCH_REQUESTED'),
  setActivities: createAction<Activity[]>('activities/ACTIVITIES_FETCH_SUCCEEDED'),
  setQuery: createAction<Query>('activities/QUERY_CHANGE'),
  setSearchTerm: createAction<string>('activities/SEARCH_TERM_CHANGE'),
  setTotal: createAction<number>('activities/TOTAL_CHANGE'),
};

// REDUCER

type State = {
  activities: Activity[];
  query: {
    limit?: number;
    skip?: number;
    sort: string;
  };
  searchTerm: string;
  total: number;
};

export const initialState: State = {
  activities: [],
  query: {
    limit: 50,
    skip: 0,
    sort: '-published',
  },
  searchTerm: '',
  total: 0,
};

export default combineReducers<State>({
  total: createReducer({}, initialState.total).on(actions.setTotal, (_, payload) => payload),
  query: createReducer({}, initialState.query).on(actions.setQuery, (_, payload) => ({...payload})),
  searchTerm: createReducer({}, initialState.searchTerm).on(
    actions.setSearchTerm,
    (_, payload) => payload,
  ),
  activities: createReducer({}, initialState.activities).on(actions.setActivities, (_, payload) => [
    ...payload,
  ]),
});

// SAGAS

const fetchActivitiesData = async ({query, searchTerm}: ActivitiesPageQuery) => {
  const sort = isSortDescending(query.sort)
    ? PanelsCurationEventsSort.PUBLISHED_DESC
    : PanelsCurationEventsSort.PUBLISHED_ASC;
  return searchActivities({
    sort,
    skip: query.skip,
    limit: query.limit,
    query: _isEmpty(searchTerm) ? '*' : `${searchTerm}*`,
  });
};

function* fetchActivitiesSaga({payload}: Action<ActivitiesPageQuery>) {
  yield put(actions.setActivities([]));
  yield put(globalActions.showLoading());
  try {
    const {total, data} = yield call(fetchActivitiesData, payload);
    yield put(actions.setTotal(total));
    yield put(actions.setActivities(data));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(globalActions.hideLoading());
  }
}

function* updateSearchQuery({payload}: Action<ActivitiesPageQuery>) {
  const {query, searchTerm} = payload;
  yield put(actions.setQuery(query));
  yield put(actions.setSearchTerm(searchTerm));
}

export function* activitiesPageSaga() {
  yield takeLatest(actions.fetchInitialData, fetchActivitiesSaga);
  yield takeLatest(actions.fetchActivities, updateSearchQuery);
  yield debounce(500, actions.fetchActivities, fetchActivitiesSaga);
}
