import type {Geometry} from './Geometry';
import {LocationType} from '../__generated__/globalTypes';

export interface Location {
  bearing: number;
  center: Geometry;
  centerGeoPoint?: {lat: number; lon: number};
  viewshed: Geometry;
  primaryStreet?: string;
  crossStreet?: string;
  neighborhood?: string;
  locality?: string;
  county?: string;
}

export const LocationTypeWithoutText = {
  [LocationType.BLOCK_GROUP]: 'location.blockGroupId',
  [LocationType.CENSUS_TRACT]: 'location.censusTractId',
  [LocationType.COUNTY]: 'location.county',
  [LocationType.CSA]: 'location.csa',
  [LocationType.CBSA]: 'location.cbsa',
  [LocationType.NEIGHBORHOOD]: 'location.neighborhood',
  [LocationType.STATE]: 'location.state',
  [LocationType.ZIP_CODE]: 'location.zip',
  [LocationType.PLACE]: 'location.place',
  [LocationType.DMA]: 'location.dmaName',
};

export interface LocationFilter {
  type: LocationType;
  typeKey: string;
  values: string[];
}
